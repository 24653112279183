import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 2; i <= 41; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/30-jan-24-1/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function RepublicDayCelebration2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">75th Republic Day Celebration</h1>

        <p>
          Chinmaya Vidyalaya celebrated the 75th Republic Day through multiple
          activities that highlighted the vibrant democratic values of India.
          <br />
          <br />
          The Pre-Primary department conducted a fancy dress competition on the
          National Symbols and their significance. The importance of the day was
          explained to the children through an engaging story and drawing the
          National Symbols.
          <br />
          <br />
          A child dressed up as Dr Ambedkar, the architect of the Constitution,
          visited all the classrooms and explained its salient features.
          <br />
          <br />
          The Primarry department celebrated by conducting a Special Assembly
          which included a patriotic song underlining the virtues of liberty and
          unity, and a special talk on the Constitution and the Parliamentary
          system.
          <br />
          <br />
          An enthralling dance performance showcased the diversity of the
          nation, and a talk show conducted by the students discussed the new
          Parliament building.
          <br />
          <br />
          The Senior Section of the school unfurled the Tiranga and discussed
          the Constitutional ideals of the nation.
          <br />
          <br />
          Ms. Archana Soni addressed the Assembly and stressed on the importance
          of discipline in building a strong character which in turn is a
          positive contributor to nation-building.
          <br />
          <br />A patriotic song and informative video on the formation and
          implementation of the Constitution completed the celebrations.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
